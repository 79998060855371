// SCSS custom utility classes

.cursor-pointer {
  cursor: pointer;
}

.object-fit-cover {
  object-fit: cover;
}

.min-height-0 {
  min-height: 0;
}

.text-small {
  font-size: 12px;
}

.text-validation {
  font-size: 14px;
  color: var(--bs-form-invalid-color);
}

.text-small-caps {
  font-variant: small-caps;
}

.max-vh-100 {
  max-height: 100vh;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.aspect-ratio-square {
  aspect-ratio: 1;
}

.contain {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.z-1 {
  z-index: 10;
}

.z-2 {
  z-index: 100;
}

.z-3 {
  z-index: 1000;
}
