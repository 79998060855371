// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
// Defaults
$theme-primary: #003763;
$theme-secondary: #007852;
$warning: #cb3838;
$button-blue: #306ecc;
$cancel: #b7b7b7;
$dark: #333;

$faded-white: #eee;
$light-gray: #ccc;
// End of my variables

$body-color: $dark;

// custom theme colors
$custom-colors: (
  "theme-primary": $theme-primary,
  "theme-secondary": $theme-secondary,
  "warning": $warning,
  "light-gray": $light-gray,
  "button-blue": $button-blue,
  "cancel": $cancel,
  "dark": $dark,
);

@media (prefers-color-scheme: dark) {
  $custom-colors: (
    "secondary": #bbb,
  );
}

// custom position values
$custom-position-values: (
  15: 15%,
  25: 25%,
  75: 75%,
);

// custom spacers
$spacer: 1rem;
$custom-spacers: (
  6: $spacer * 4,
  7: $spacer * 6,
  8: $spacer * 10,
  9: $spacer * 15,
);

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$theme-colors: map-merge($theme-colors, $custom-colors);
$position-values: map-merge($position-values, $custom-position-values);
$spacers: map-merge($spacers, $custom-spacers);

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

$utilities: map-merge(
  $utilities,
  (
    "viewport-height":
      map-merge(
        map-get($utilities, "viewport-height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "viewport-height"), "values"),
              (
                75: 75vh,
              )
            ),
        )
      ),
  ),
  (
    "max-height":
      map-merge(
        map-get($utilities, "max-height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "max-height"), "values"),
              (
                50: 50%,
              )
            ),
        )
      ),
  )
);

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here
.btn-outline-blue {
  @include button-outline-variant($button-blue);
}

.modal-content,
.modal-header,
.modal-footer {
  border-radius: 0;
}

.btn-outline-success {
  --bs-btn-color: var(--bs-success);
  --bs-btn-border-color: var(--bs-success);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-success);
  --bs-btn-hover-border-color: var(--bs-success);
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-success);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-success);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-success);
  --bs-gradient: none;
}

/* These classes are used to layer the modal backdrop above the modal below on nested modals. */
.nested-backdrop {
  z-index: 1055;
}

.nested-dialog {
  z-index: 1060;
}
