@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap");

:root {
  --mobile-nav-height: 5.5rem;
  --desktop-nav-width: 10rem;
  --mobile-top-bar-height: 4rem;
  --desktop-top-bar-height: 5rem;
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bs-body-bg: #333;
    --bs-body-bg-rgb: 51, 51, 51;
    --bs-secondary-bg: #383838;
    --bs-tertiary-bg: #444;
    --bs-body-color: #ddd;
    --bs-primary: rgb(79, 149, 255);
    --bs-primary-rgb: 79, 149, 255;
    --bs-primary-color: 79, 149, 255, 0.75;
    --bs-secondary: rgb(195, 195, 195);
    --bs-secondary-rgb: 195, 195, 195;
    --bs-secondary-color: 195, 195, 195, 0.75;
    --bs-success: rgb(30, 202, 122);
    --bs-success-rgb: 30, 202, 122;
    --bs-success-color: 30, 202, 122, 0.75;
    --bs-danger: rgb(256, 115, 100);
    --bs-danger-rgb: 256, 115, 100;
    --bs-danger-color: 256, 115, 100, 0.75;
    --bs-emphasis-color: #eee;
    --bs-link-color: rgb(79, 149, 255);
    --bs-pagination-color: rgb(79, 149, 255);
    --bs-close-btn-color: #fff;
  }

  a {
    color: var(--bs-primary);
  }

  /* Fixes dark on dark toast close btn icon */
  .btn-close {
    filter: var(--bs-btn-close-white-filter);
  }

  /* Fixes dark on dark form select downward caret icon */
  .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  }
}

html {
  font-family: "Roboto", Arial, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Layout */
main {
  flex-grow: 1;
  overflow: auto;
}

.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.page {
  max-height: 100%;
  height: 100%;
}

.footer-label {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}

@media screen and (min-width: 992px) {
  main {
    margin: 0;
  }

  .layout {
    flex-direction: row;
    position: static;
  }
}

/* End of Layout */

/* Main Navbar */
.navbar-container {
  order: 1;
  flex-shrink: 0;
  bottom: -1px;
  background-color: var(--bs-theme-primary);
  height: var(--mobile-nav-height);
  min-height: var(--mobile-nav-height);
  max-height: var(--mobile-nav-height);
  width: 100%;
  position: relative;
}

.main-nav-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-theme-primary);
  border: none;
  z-index: 1;
}

.main-nav-item.active::before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  position: absolute;
  content: "";
  background-color: black;
  opacity: 0.3;
  z-index: -1;
}

.disabled-nav-link {
  color: rgba(255, 255, 255, 0.5) !important;
  pointer-events: none;
}

.desktop-nav {
  display: none;
}

.short-desktop-nav {
  display: none;
}

.mobile-nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.mobile-nav .main-nav-item {
  height: var(--mobile-nav-height);
}

.main-nav-more-options {
  position: absolute;
  top: 0;
  min-height: var(--mobile-nav-height);
  z-index: -1;
  transition: transform 0.2s;
}

.main-nav-more-options.open {
  transform: translateY(-100%);
}

@media screen and (min-width: 992px) {
  .navbar-container {
    order: 0;
    position: sticky;
    top: 0;
    height: 100vh;
    padding: 1rem 0;
    min-width: var(--desktop-nav-width);
    max-width: var(--desktop-nav-width);
    min-height: 100vh;
    max-height: 100vh;
  }

  .main-nav-item {
    flex-grow: 0;
    width: var(--desktop-nav-width);
  }

  .main-nav-item.active::before {
    height: 120%;
    top: -10%;
  }

  .desktop-nav {
    display: flex;
  }

  .mobile-nav {
    display: none;
  }

  .nav-item-gap-below {
    margin-bottom: auto;
  }
}

@media screen and (min-width: 992px) and (max-height: 800px) {
  .navbar-container {
    min-width: calc(var(--desktop-nav-width) / 2);
    max-width: calc(var(--desktop-nav-width) / 2);
    padding: 0;
  }

  .desktop-nav {
    display: none;
  }

  .short-desktop-nav {
    display: block;
  }

  .short-desktop-nav .nav-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    align-items: start;
    padding: 1rem 0;
    transform: translateX(calc(-100% + calc(var(--desktop-nav-width) / 2)));
    background-color: var(--bs-theme-primary);
    transition: transform .5s;
  }

  .short-desktop-nav .nav-container nav {
    width: max-content;
  }

  .short-desktop-nav .menu-btn {
    min-width: calc(var(--desktop-nav-width) / 2);
  }

  .short-desktop-nav .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .25);
    opacity: 0;
    transition: opacity .5s;
    pointer-events: none;
    z-index: -1;
  }

  .short-desktop-nav.expanded .nav-container {
    transform: translateX(0);
  }

  .short-desktop-nav.expanded .bg {
    opacity: 1;
  }

  .nav-item-gap-below {
    margin-bottom: 0;
  }
}

/* End of Main Navbar */

/* Top Bar */

.top-bar {
  height: var(--desktop-top-bar-height);
}

.top-bar-controls {
  flex-grow: 1;
}

.mobile-header {
  max-height: var(--mobile-top-bar-height);
  min-height: var(--mobile-top-bar-height);
}

.page-heading {
  display: none;
}

@media screen and (min-width: 992px) {
  .top-bar-controls {
    flex-grow: 0;
  }

  .page-heading {
    display: flex;
  }

  /* in between every child of top-bar-controls */
  .top-header .vr,
  .top-bar-controls>*:not(:last-child)::after {
    display: block;
    content: "";
    height: 3rem;
    min-width: 3px;
    background-color: white;
    opacity: 0.25;
    margin-left: 0.5rem;
  }
}

/* End of Top Bar */

/* Sub Navbar */
.sub-nav {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-100%);
  flex-direction: column;
  padding-bottom: 1rem;
  background-color: var(--bs-theme-secondary);
  width: 100vw;
  height: fit-content;
  z-index: -1;
  transition: transform 0.2s;
}

.sub-nav.expanded {
  transform: translateY(0);
}

.sub-nav-item {
  padding: 1rem;
}

.sub-nav-item.active::before {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  background-color: black;
  opacity: 0.3;
  z-index: -1;
}

@media screen and (min-width: 992px) {
  .sub-nav {
    position: static;
    flex-direction: row;
    padding: 0;
    width: auto;
    transform: translateY(0);
    z-index: 100;
  }

  .sub-nav-item {
    padding: 0 0.75rem;
  }

  .sub-nav-item.active::before {
    height: var(--desktop-top-bar-height);
  }
}

/* End of Sub Navbar */

/* Auth */
.bg-auth {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .3)), url('../public/img/login.jpg');
  background-size: cover;
  background-position: bottom center;
  filter: blur(3px);
}

@media screen and (min-width: 992px) {
  .bg-auth {
    background-position: center;
  }
}

/* End of Auth */

/* Gallery */
.loadable-image {
  transition: opacity 0.2s;
}

.loadable-image:not(.loaded-img) {
  opacity: 0;
}

.loadable-image.loaded-img {
  opacity: 1;
}

/* End of Gallery */

/* Grid View */
.photo-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.photo-grid.voting-grid {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: auto;
}

.voting-grid>div {
  max-width: 350px;
}

.photo-grid-item img {
  transform: scale(1);
  transition: transform 0.2s;
}

.photo-grid-item:hover img {
  transform: scale(1.025);
}

.category-ribbon {
  top: 0.75rem;
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0.25rem 0.25rem 5px black;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
}

.photo-grid-item.voted-photo .img-container {
  outline: 3px solid goldenrod;
  border-radius: .25rem;
}

@media screen and (min-width: 568px) {
  .photo-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
    width: auto;
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .photo-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .category-ribbon {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .photo-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* End of Grid View */

/* Feed View */
.photo-feed {
  margin: auto;
  max-width: 450px;
}

.photo-feed-item p,
.photo-feed-item h2,
.photo-feed-item h3 {
  margin: 0;
}

.feed-photo-section {
  border-bottom: 1px solid lightgray;
  padding: 0.5rem 0;
}

.blur {
  filter: blur(6px);
  background-size: cover;
  transform: scale(1.05);
}

.dim::before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
}

.profile-link {
  color: inherit;
  text-underline-offset: .25rem;
}

.project-info-container {
  flex-grow: 1;
  background-color: #eee;
  padding: 0.25rem;
}

.comment-list {
  max-height: 200px;
}

@media screen and (min-width: 1200px) {
  .photo-feed {
    margin: auto;
    max-width: 600px;
  }
}

/* End of Feed View */

/* My Photos */
.count-bubble {
  border-radius: 20rem;
  height: 1.5em;
  padding: 0 0.5em;
  text-align: center;
  font-size: 1.1rem;
  background-color: var(--bs-primary);
  color: var(--bs-body-bg);
}

.my-photo-card {
  width: 300px;
}

.my-photo-card-btn {
  flex-basis: 50%;
}

/* End of My Photos */

/* Moderate */
.moderate-index-link {
  height: 100%;
  color: inherit;
  font-size: 1.2rem;
  font-weight: bold;
  text-underline-offset: .25rem;
}

.moderate-index-link::after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: currentcolor;
  clip-path: polygon(10% 0%,
      90% 50%,
      10% 100%,
      10% 80%,
      58% 50%,
      10% 20%);
  margin-left: .5rem;
  margin-right: .25rem;
  vertical-align: middle;
  transition: margin .2s ease-in-out;
}

.moderate-index-link:hover::after {
  margin-left: .75rem;
  margin-right: 0;
}

.page-link:disabled {
  opacity: 0.75;
}

/* End of Moderate */

/* Voting */
.vote-balance {
  font-size: 1rem;
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

.star-icon {
  color: goldenrod;
}

@media screen and (min-width: 756px) {
  .vote-balance {
    font-size: 1.4rem;
  }
}

/* End of Voting */

/* Moderate */
.moderation-mobile-notice {
  height: 500px;
}

/* End of Moderate */

/* Mobile Help */
.mobile-help-button {
  position: fixed;
  left: .5rem;
  bottom: calc(.5rem + var(--mobile-nav-height));
}

@media screen and (min-width: 992px) {
  .mobile-help-button {
    display: none;
  }
}

/* end of Mobile Help */

/* Modals */
.modal-header {
  border-bottom: none;
}

.step-instructions {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  gap: 1rem;
  counter-reset: stepinstructions;
}

.step-instructions li {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: start;
}

.step-instructions li::before {
  counter-increment: stepinstructions;
  content: counter(stepinstructions);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 1.25rem;
  background-color: var(--bs-theme-primary);
  color: white;
}

.onboarding-card {
  min-height: 75vh;
}

.content-center {
  opacity: 1;
  transform: translateX(0);
}

.content-left {
  opacity: 0;
  transform: translateX(-100%);
}

.content-right {
  opacity: 0;
  transform: translateX(100%);
}

.animate-content {
  transition: opacity 0.2s, transform 0.2s;
}

.dont-animate-content {
  transition: none;
}

.help-modal h3 {
  text-align: center;
}

.constrain-size {
  max-width: 100vw;
  max-height: 100vh;
}

.lightbox {
  z-index: 2000;
}

/* End of Modals */

/* Loading */
.dimmer {
  background-color: white;
  opacity: 0.5;
}

.loader {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}

.loading-screen-logo {
  width: 600px;
  max-width: 100%;
  padding: .5rem;
}

/* End of Loading */

/* Toast */
.toast-container {
  position: fixed;
  top: calc(var(--mobile-top-bar-height) + 1.5rem);
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 992px) {
  .toast-container {
    top: unset;
    left: unset;
    bottom: 1rem;
    right: 1rem;
    transform: none;
  }
}

/* End of Toast */

/* Prizes Page */
.prizes-board {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: fit-content;
}

.prizes-board>li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 2px solid var(--bs-secondary);
  padding: 1rem;
  margin-bottom: 1rem;
}

/* End of Prizes Page */

/* Theme Switcher */
.theme-switcher {
  z-index: 1;
  bottom: var(--mobile-nav-height);
  right: 0;
}

@media screen and (min-width: 992px) {
  .theme-switcher {
    bottom: 0;
    left: var(--desktop-nav-width);
    right: auto;
  }
}

/* End of Theme Switcher */
